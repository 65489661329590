<template>
  <Layout>
    <Header class="header">
      <Breadcrumb class="breadcrumb">
        <BreadcrumbItem to="/sys_manage/role">系统角色</BreadcrumbItem>
        <BreadcrumbItem>修改</BreadcrumbItem>
      </Breadcrumb>
    </Header>
    <Content class="content">
      <Form ref="dataForm" :model="formData" :rules="rules" :label-width="100" >
        <FormItem label="角色名称：" prop="roleName">
          <Input v-model="formData.roleName" />
        </FormItem>
        <FormItem label="角色简介：" prop="roleDesc">
          <Input type="textarea" v-model="formData.roleDesc" />
        </FormItem>
        <FormItem>
          <Button style="background: #00A0E9;" type="primary" @click="submit">保存</Button>
        </FormItem>  
      </Form>  
    </Content>
  </Layout>
</template>

<script>
import Vue from 'vue'
import request from '@/utils/request'
export default Vue.extend({
    data() {
        return {
          id: null,
          formData: {
              roleName: '',
              roleDesc: '',
          },
          rules: {
              roleName: [{ required: true, message: '角色名称不能为空', trigger: 'blur' }],
              roleDesc: [{ required: true, message: '角色简介不能为空', trigger: 'blur' }],
          },
        }
    },
    mounted() {
      this.id = this.$route.params.id
      this.getData()
    },
    methods: {
      getData() {
        request.get(`/api/sys/role/${this.id}`)
        .then((data) => this.formData = data)
      },
      submit() {
        const params = this.formData
        this.$refs.dataForm.validate((valid) => {
          if (valid) {
            request.put(`/api/sys/role/${this.id}`, params)
            .then((res) => {
              this.$Message.success('修改成功!')
              this.$router.replace('/sys_manage/role')
            })
          }
        })
      },
    },
})
</script>

<style scoped>
  .ivu-layout {
    background: transparent;
    height: 100%;
  }
  .header{
    height: 40px;
    line-height: 40px;
    background: transparent;
  }
  ::v-deep .ivu-breadcrumb{
    font-size: 16px;
  }
  ::v-deep .ivu-breadcrumb a{
    color: #0077EE;
  }
  ::v-deep .ivu-breadcrumb-item-separator{
    color: #0077EE;
  }
  ::v-deep .ivu-breadcrumb>span:last-child{
    color: #0077EE;
  }
  .content {
    display: flex;
    justify-content: center;
  }
  form {
    width: 450px;
  }
  ::v-deep .ivu-form .ivu-form-item-label{
   color: #0077EE;
 }
 ::v-deep .ivu-input{
   background-color: transparent;
   color: #0077EE;
   border-color: #0077EE;
 }
 ::v-deep .ivu-input-number{
   background-color: transparent;
   border-color: #0077EE;
 }
 ::v-deep .ivu-input-number-input{
   background-color: transparent;
   color: #0077EE;
 }
 ::v-deep .ivu-select-selection{
   background-color: transparent;
   border-color: #0077EE;
   color: #0077EE;
 }
 ::v-deep .ivu-spin-fix{
   background-color: transparent;
   border-color: #0077EE;
 }
 ::v-deep .ivu-table-wrapper>.ivu-spin-fix{
   border: none;
 }
</style>
